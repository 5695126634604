import { Ability } from "@casl/ability";
import { permissions_abilities } from "./permissions";

export function get_abilities() {
  const user = JSON.parse(localStorage.getItem("user"));
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  var is_one_click_client = String(localStorage.getItem("user_is_one_click_client"));
  var ocd_mode = String(localStorage.getItem("ocd_mode"));
  let is_support_point = user?.is_support_point || null;
  var abilities = [];

  permissions?.forEach(permission => {
    if (permission.code in permissions_abilities && permission.has_permission) {
      let ability = permissions_abilities[permission.code]
      abilities.push({ action: ability.action, subject: ability.subject })
    }
  });

  if (user?.role == "SYSTEM_ADMIN") {
    abilities.push({ action: "set", subject: "users-permissions" });
    abilities.push({ action: "see", subject: "internal-companies" });
    abilities.push({ action: "change", subject: "orders-table-fields" });
    abilities.push({ action: "see", subject: "admin-permissions" });
    abilities.push({ action: "see", subject: "company-kind" });
    abilities.push({ action: "see", subject: "calendar" });
    abilities.push({ action: "create", subject: "main-accounts" });
    abilities.push({ action: "see", subject: "all-companies" });
    abilities.push({ action: "see", subject: "all-company-accounts" });
    abilities.push({ action: "force", subject: "assign-orders" });
    abilities.push({ action: "see", subject: "shipping-data" });
    abilities.push({ action: "change", subject: "system-admin-role" });
    //sidebar menu
    abilities.push({ action: "sidebar", subject: "ui-dashboard" });
    abilities.push({ action: "sidebar", subject: "ui-dashboard-indicators" });
    abilities.push({ action: "sidebar", subject: "ui-dashboard-store" });        
    abilities.push({ action: "sidebar", subject: "ui-orders" });

    //geo audit
    abilities.push({ action: "view", subject: "geo-audit" });

    //pages
    abilities.push({ action: "editAccount", subject: "user" });
    abilities.push({ action: "view", subject: "user" });
    abilities.push({ action: "edit", subject: "user" });
    abilities.push({ action: "edit", subject: "user-account" });
    abilities.push({ action: "edit", subject: "user-role" });
    abilities.push({ action: "allow", subject: "one-click-access" });
    abilities.push({ action: "create", subject: "orders-own" });
    abilities.push({ action: "edit", subject: "orders" });
    abilities.push({ action: "remove", subject: "orderOccurrences" });
    abilities.push({
      action: "selectTransporterAccount",
      subject: "via-dashboard-support-point",
    });
    abilities.push({ action: "scheduleOrder", subject: "orders" });

    if (ocd_mode != "true") {
      //sidebar menu
      abilities.push({ action: "sidebar", subject: "ui-resumos" });
      abilities.push({ action: "sidebar", subject: "ui-sla" });
      abilities.push({ action: "sidebar", subject: "ui-estoque" });
      abilities.push({ action: "select", subject: "ui-estoque-company" });
      abilities.push({ action: "sidebar", subject: "ui-estoque-dev-out" });
      abilities.push({ action: "sidebar", subject: "ui-relatorios" });
      abilities.push({ action: "sidebar", subject: "ui-configuracoes" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-companies" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-accounts" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-shipping" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-users" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-occurrences" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-holidays" });
      abilities.push({ action: "sidebar", subject: "ui-apis" });
      abilities.push({ action: "sidebar", subject: "ui-invoices" });


      //pages
      abilities.push({ action: "create", subject: "orders" });
      abilities.push({ action: "printTag", subject: "orders" });
      abilities.push({ action: "selectTransporterAccount", subject: "orders" });
      abilities.push({ action: "setRoutes", subject: "orders" });
      abilities.push({ action: "view", subject: "lots" });
      abilities.push({ action: "edit", subject: "lots" });
    }
  }

  if (user?.role == "COMPANY_ADMIN") {
    abilities.push({ action: "set", subject: "users-permissions" });
    abilities.push({ action: "view", subject: "lots" });
    abilities.push({ action: "change", subject: "orders-table-fields" });
    abilities.push({ action: "see", subject: "own-company" });
    //geo audit
    abilities.push({ action: "view", subject: "geo-audit" });

    abilities.push({ action: "see", subject: "all-company-accounts" });

    //pages
    abilities.push({ action: "editAccount", subject: "user" });
    abilities.push({ action: "view", subject: "user" });
    abilities.push({ action: "edit", subject: "user" });
    abilities.push({ action: "see", subject: "calendar" });
    abilities.push({ action: "edit", subject: "user-account" });
    abilities.push({ action: "edit", subject: "user-role" });
    abilities.push({ action: "edit", subject: "orders" });
    abilities.push({ action: "scheduleOrder", subject: "orders" });
    abilities.push({ action: "sidebar", subject: "ui-estoque" });
    abilities.push({ action: "sidebar", subject: "ui-estoque-dev-out" });
    abilities.push({ action: "sidebar", subject: "ui-cadastros" });
    abilities.push({ action: "sidebar", subject: "ui-cadastros-users" });
    abilities.push({ action: "sidebar", subject: "ui-cadastros-accounts" });
    abilities.push({ action: "sidebar", subject: "ui-dashboard" });
    abilities.push({ action: "sidebar", subject: "ui-dashboard-indicators" });
    abilities.push({ action: "sidebar", subject: "ui-invoices" });
  }
  
  if (is_support_point == true) {
    abilities.push({ action: "scheduleOrder", subject: "orders" });
  }
  if ((is_one_click_client == "true" || ocd_mode == "true") && user?.role != "USER") {
    abilities.push({ action: "sidebar", subject: "ui-dashboard-store" });
    abilities.push({ action: "create", subject: "orders-own" });
    abilities.push({ action: "view", subject: "store-check-in" });
  }
  if (user?.role == "ACCOUNT_ADMIN") {
    abilities.push({ action: "set", subject: "users-permissions" });
    abilities.push({ action: "see", subject: "calendar" });
    abilities.push({ action: "see", subject: "own-account" });
    abilities.push({ action: "view", subject: "geo-audit" });
    abilities.push({ action: "view", subject: "user" });
    abilities.push({ action: "edit", subject: "user" });
    abilities.push({ action: "sidebar", subject: "ui-dashboard" });
    abilities.push({ action: "sidebar", subject: "ui-dashboard-indicators" });
    if (ocd_mode != "true") {
      abilities.push({ action: "view", subject: "lots" });
      abilities.push({ action: "sidebar", subject: "ui-estoque" });
      abilities.push({ action: "sidebar", subject: "ui-estoque-dev-out" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros" });
      abilities.push({ action: "sidebar", subject: "ui-cadastros-users" });
    }
  }
  if (user?.role == "USER") {
    abilities.push({ action: "view", subject: "own-user" });
    abilities.push({ action: "edit", subject: "own-user" });
  }
  return abilities;
}

var abilities = get_abilities();
export const abilities_return = abilities;
export const ability = new Ability(abilities);
export default new Ability(abilities);
